import {
  EpisodeGenerationStatus,
  useCreateAiEpisodeJobMutation,
  useEpisodeGenerationStatusQuery,
} from '@maestro/graphql';
import { useEffect, useState } from 'react';
import { useStudioFlowStore } from '../../../../hooks/useStudioFlowStore';

export const useGenerateAiEpisode = () => {
  const { seriesId } = useStudioFlowStore();
  const { mutateAsync: createAiJob } = useCreateAiEpisodeJobMutation();
  const [jobId, setJobId] = useState<string | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const { data, remove, refetch } = useEpisodeGenerationStatusQuery(
    { input: { jobId: jobId ?? '' } },
    { enabled: !!jobId },
  );

  useEffect(() => {
    if (isGenerating) {
      const interval = setInterval(() => refetch(), 1000);

      return () => clearInterval(interval);
    }
  }, [isGenerating]);

  useEffect(() => {
    if (data?.episodeGenerationStatus?.job) {
      const { status, episodeId } = data.episodeGenerationStatus.job;

      const isPlayable = [
        EpisodeGenerationStatus.Playable,
        EpisodeGenerationStatus.Succeeded,
      ].includes(status);

      if (isPlayable) {
        setIsGenerating(false);
        window.location.href = `${window.location.origin}/studio/${episodeId}`;
        remove();
        setJobId(null);
      }
    }
  }, [data]);

  const generate = async (
    props: Record<string, string | number>,
  ): Promise<void> => {
    const { createAiEpisodeJob } = await createAiJob({
      input: { props, seriesId },
    });

    if (createAiEpisodeJob.error) {
      // eslint-disable-next-line no-console
      console.error(createAiEpisodeJob.error);
      throw new Error('Failed to generate episode');
    }

    setIsGenerating(true);
    setJobId(createAiEpisodeJob.jobId);
  };

  return { generate, isGenerating };
};
