import { Button } from '@chakra-ui/react';
import {
  HeaderContainer,
  HeaderRowContainer,
  HeaderTitle,
  ScreenContainer,
  SectionCard,
  SectionContainer,
} from '@maestro/components';
import { useListStoryFactoryQuery } from '@maestro/graphql';
import { dimensions, textStyles } from '@maestro/styles';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { EmptyState } from '../../../components/EmptyState';
import { TableLoader } from '../../../components/TableLoader';

export const StoryFactoryList = () => {
  const { data, isLoading } = useListStoryFactoryQuery({});
  const navigate = useNavigate();
  const list = data?.storyFactories ?? [];

  return (
    <ScreenContainer>
      <HeaderContainer>
        <HeaderRowContainer>
          <HeaderTitle>Story Factory</HeaderTitle>
        </HeaderRowContainer>
      </HeaderContainer>

      <SectionContainer>
        <SectionCard>
          {isLoading && list.length === 0 ? (
            <TableLoader />
          ) : list.length > 0 ? (
            <StoryFactoryListContainer>
              {list.map((item, index) => (
                <div key={item.id}>
                  <StoryFactoryContainer>
                    <StoryFactoryDetails>
                      <StoryFactoryName>{item.title}</StoryFactoryName>
                    </StoryFactoryDetails>
                    <Button
                      variant="default"
                      onClick={() => navigate(`/story-factory/${item.id}`)}
                    >
                      View
                    </Button>
                  </StoryFactoryContainer>
                  {index < list.length - 1 && <Divider />}
                </div>
              ))}
            </StoryFactoryListContainer>
          ) : (
            <EmptyState title="No story factory found." />
          )}
        </SectionCard>
      </SectionContainer>
    </ScreenContainer>
  );
};

const StoryFactoryListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StoryFactoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size16};
  align-items: center;
  padding: ${dimensions.size8};
`;

const StoryFactoryDetails = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${dimensions.size8};
  align-items: start;
  justify-content: center;
`;

const StoryFactoryName = styled.div`
  ${textStyles.label.lb16sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const Divider = styled.div`
  width: 100%;
  height: ${dimensions.size1};
  margin: ${dimensions.size24} 0;
  background: ${({ theme }) => theme.colors.border.default[100]};
`;
