import {
  List,
  ListBody,
  ListHeader,
  ListHeaderColumn,
} from '@maestro/components';
import { StoryFactoryFragment } from '@maestro/graphql';
import { dimensions } from '@maestro/styles';
import React from 'react';
import { FieldRow } from './FieldRow';

type Props = {
  fields: StoryFactoryFragment['fields'];
};

export const FieldsList: React.FC<Props> = ({ fields }) => {
  return (
    <List>
      <ListHeader>
        <ListHeaderColumn width="30%">Name</ListHeaderColumn>
        <ListHeaderColumn flex>Description</ListHeaderColumn>
        <ListHeaderColumn width={dimensions.size120} />
      </ListHeader>
      <ListBody>
        {fields.map((field) => (
          <FieldRow data={field} />
        ))}
      </ListBody>
    </List>
  );
};
