import { Button } from '@chakra-ui/react';
import { Icon, ListItem, ListItemColumn } from '@maestro/components';
import { FieldDefinition, FieldType } from '@maestro/graphql';
import { dimensions, rawDimensions } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';

type PropertyFormProps = {
  data: FieldDefinition;
  onChange?: (data: FieldDefinition) => void;
  onRemove?: () => void;
  onEdit?: () => void;
};

const icons: Record<FieldType, string> = {
  [FieldType.Number]: 'number-type',
  [FieldType.Text]: 'text-type',
  [FieldType.Select]: 'enum-type',
};

export const FieldRow: React.FC<PropertyFormProps> = (props) => {
  const icon = icons[props.data.type];

  return (
    <ListItem onClick={props.onEdit}>
      <ListItemColumn width="30%">
        <Icon name={icon} size={rawDimensions.size16} />
        {props.data.name || <Empty>Empty</Empty>}
      </ListItemColumn>
      <ListItemColumn flex>
        {props.data.description || <Empty>Empty</Empty>}
      </ListItemColumn>
      <ListItemColumn reverse width={dimensions.size120}>
        {props.onEdit && (
          <Button
            variant="inputButton"
            background="transparent"
            color="text.placeholder"
          >
            <Icon name="edit" size={rawDimensions.size20} />
          </Button>
        )}

        {props.onRemove && (
          <Button
            variant="inputButton"
            background="transparent"
            color="text.placeholder"
            onClick={(e) => {
              e.stopPropagation();
              props.onRemove?.();
            }}
          >
            <Icon name="trash" size={rawDimensions.size20} />
          </Button>
        )}
      </ListItemColumn>
    </ListItem>
  );
};

const Empty = styled.div`
  color: ${({ theme }) => theme.colors.text.placeholder};
`;
